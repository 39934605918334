// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ozxC7RAjO"];

const serializationHash = "framer-kqviX"

const variantClassNames = {ozxC7RAjO: "framer-v-19okerg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, cOxJ7FGW3: image ?? props.cOxJ7FGW3} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cOxJ7FGW3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ozxC7RAjO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19okerg", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ozxC7RAjO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 96, borderBottomRightRadius: 96, borderTopLeftRadius: 96, borderTopRightRadius: 96, ...style}}><Image background={{alt: "", fit: "fill", sizes: "32px", ...toResponsiveImage(cOxJ7FGW3)}} className={"framer-1esthy9"} layoutDependency={layoutDependency} layoutId={"PwEkgay2N"} style={{borderBottomLeftRadius: 96, borderBottomRightRadius: 96, borderTopLeftRadius: 96, borderTopRightRadius: 96}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kqviX.framer-gq5whd, .framer-kqviX .framer-gq5whd { display: block; }", ".framer-kqviX.framer-19okerg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-kqviX .framer-1esthy9 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 32px); overflow: hidden; position: relative; width: 32px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kqviX.framer-19okerg { gap: 0px; } .framer-kqviX.framer-19okerg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-kqviX.framer-19okerg > :first-child { margin-left: 0px; } .framer-kqviX.framer-19okerg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"cOxJ7FGW3":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkpE6gFYsF: React.ComponentType<Props> = withCSS(Component, css, "framer-kqviX") as typeof Component;
export default FramerkpE6gFYsF;

FramerkpE6gFYsF.displayName = "Memoji";

FramerkpE6gFYsF.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerkpE6gFYsF, {cOxJ7FGW3: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerkpE6gFYsF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})